<template>
  <div id="app">
    <router-view /> 
  </div>
</template>

<script>
export default {
  name: 'App',
  
};
</script>

<style>
body{
  background-color: #292D7D;
}
</style>
