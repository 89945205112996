<template>
    <div class="email-verification-page">
        <div class="container">
            <h1>Verifica la tua Email</h1>
            <p v-if="!successMessage">Attendi mentre verifichiamo il tuo account...</p>

            <!-- Messaggi di successo o errore -->
            <p v-if="successMessage" class="white-text">{{ successMessage }}</p>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>

            <button v-if="successMessage" @click="goToHomePage" class="btn btn-custom">Vai alla Home</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            token: null, // Token recuperato dall'URL
            successMessage: '',
            errorMessage: '',
        };
    },
    created() {
        // Recupera il token dall'URL
        this.token = this.$route.query.token || null;

        // Se il token esiste, effettua la richiesta di verifica
        if (this.token) {
            this.verifyEmail();
        } else {
            this.errorMessage = 'Token non valido. Non è possibile verificare l\'account.';
        }
    },
    methods: {
        async verifyEmail() {
            try {
                // Effettua la richiesta per verificare l'email
                const response = await fetch(`https://elitefitness.utopialabs.it/api/auth/verify?token=${this.token}`, {
                    method: 'GET'
                });

                if (response.ok) {
                    this.successMessage = 'La tua email è stata verificata con successo!';
                    this.errorMessage = '';
                } else {
                    this.errorMessage = 'Verifica fallita. Token non valido o scaduto.';
                    this.successMessage = '';
                }
            } catch (error) {
                console.error('Email verification failed:', error);
                this.errorMessage = 'Si è verificato un errore durante la verifica. Riprova più tardi.';
                this.successMessage = '';
            }
        },
        goToHomePage() {
            this.$router.push('/');
        }
    }
};
</script>

<style scoped>
.email-verification-page {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #292D7D;
    color: white;
}

.text-danger {
    color: red;
}

.text-success {
    color: rgb(255, 255, 255);
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
}

.btn-custom:hover {
    background-color: #cc2e31;
    color: white;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    margin: 10px;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}
</style>
