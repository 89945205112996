<template>
    <div class="gender-selection">
      <div class="gender-grid">
        <div v-for="gender in genders" :key="gender.value"
          @click="selectGender(gender)"
          :class="['gender-option', { selected: selectedGender === gender.value }]">
          <div class="gender-title">{{ gender.label }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      modelValue: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        selectedGender: this.modelValue,
        genders: [
          { label: 'Maschio', value: 'MALE' },
          { label: 'Femmina', value: 'FEMALE' },
          { label: 'Altro', value: 'OTHER' }
        ]
      };
    },
    watch: {
      selectedGender(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    },
    methods: {
      selectGender(gender) {
        this.selectedGender = gender.value;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Layout della griglia per i bottoni di genere */
  .gender-grid {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  /* Stile base per ogni opzione di genere */
  .gender-option {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    flex-grow: 1;
    font-family: 'Montserrat', sans-serif;
  }
  
  /* Quando un'opzione è selezionata */
  .gender-option.selected {
    border-color: #e83539;
  }
  
  /* Stile per il titolo di ogni opzione */
  .gender-title {
    font-size: 1rem;
    font-weight: 500;
  }
  </style>
  