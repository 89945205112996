<template>
    <header>
        <nav class="navbar navbar-expand-lg">
            <div class="container d-flex justify-content-between align-items-center">
                <a class="navbar-brand" href="https://elitefitness.it">
                    <img src="@/assets/logo.png" alt="Elite Fitness Logo" width="60" height="60"
                        class="d-inline-block align-top">
                </a>
                <div>
                    <a href="https://elitefitness.it/app" class="btn btn-custom">Torna all'app</a>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    name: 'HeaderMenu'
}
</script>

<style scoped>
.container {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 15px;
}

.navbar {
    background-color: #30348e;
    padding: 1rem 0;
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
}

.btn-custom:hover {
    background-color: #cc2e31;
    color: white;
}

.navbar-brand,
.btn-link {
    color: white;
}

.btn-link:hover {
    color: #f8f9fa;
}
</style>