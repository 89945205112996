<template>
    <div class="password-reset-page">
        <div class="container">
            <h2 v-if="!token" class="fw-bold">Resetta la tua password</h2>
            <h2 v-else class="fw-bold">Inserisci una nuova password.</h2>

            <p v-if="!token" >Inserisci la tua email per ricevere un link di reimpostazione della password.</p>

            <!-- Form per inviare email di reset -->
            <form v-if="!token" @submit.prevent="resetPassword">
                <div class="form-group">
                    <input v-model="email" type="email" placeholder="Email" class="form-control" required />
                </div>
                <button type="submit" class="btn btn-custom" :disabled="isLoading">
                    <span v-if="!isLoading">Invia Email di Reset</span>
                    <span v-else class="loading-spinner"></span>
                </button>
            </form>

            <!-- Form per inserire la nuova password -->
            <form v-else @submit.prevent="updatePassword">
                <div class="form-group">
                    <input v-model="newPassword" type="password" placeholder="Nuova Password" class="form-control" required />
                </div>
                <div class="form-group">
                    <input v-model="confirmPassword" type="password" placeholder="Conferma Password" class="form-control" required />
                </div>
                <button type="submit" class="btn btn-custom" :disabled="isLoading">
                    <span v-if="!isLoading">Aggiorna Password</span>
                    <span v-else class="loading-spinner"></span>
                </button>
            </form>

            <!-- Messaggi di successo o errore -->
            <p v-if="successMessage" class="text-success">{{ successMessage }}</p>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            newPassword: '',
            confirmPassword: '',
            token: null,
            successMessage: '',
            errorMessage: '',
            isLoading: false,
        };
    },
    created() {
        this.token = this.$route.query.token || null;
    },
    methods: {
        async resetPassword() {
            this.isLoading = true;
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/reset-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.email,
                    }),
                });

                if (response.ok) {
                    this.successMessage = 'Email di reset inviata con successo. Controlla la tua casella di posta.';
                    this.errorMessage = '';
                } else {
                    this.errorMessage = 'Email non trovata o richiesta non valida. Riprova.';
                    this.successMessage = '';
                }
            } catch (error) {
                console.error('Password reset request failed:', error);
                this.errorMessage = 'Si è verificato un errore durante il reset della password. Riprova più tardi.';
                this.successMessage = '';
            } finally {
                this.isLoading = false;
            }
        },
        async updatePassword() {
            if (this.newPassword !== this.confirmPassword) {
                this.errorMessage = 'Le password non coincidono. Riprova.';
                return;
            }

            this.isLoading = true;
            try {
                const response = await fetch('https://elitefitness.utopialabs.it/api/auth/update-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: this.token,
                        newPassword: this.newPassword,
                    }),
                });

                if (response.ok) {
                    this.successMessage = 'Password aggiornata con successo.';
                    this.errorMessage = '';
                    this.$router.push('/login');
                } else {
                    this.errorMessage = 'Token non valido o richiesta non valida. Riprova.';
                    this.successMessage = '';
                }
            } catch (error) {
                console.error('Password update failed:', error);
                this.errorMessage = 'Si è verificato un errore durante l\'aggiornamento della password. Riprova più tardi.';
                this.successMessage = '';
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

.password-reset-page {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #292D7D;
    color: white;
}

@media (min-width: 1400px) {
    .password-reset-page .container {
        width: 600px;
    }
}

.text-danger {
    color: red;
}

.text-success {
    color: rgb(255, 255, 255);
}

.btn-custom {
    font-family: 'Montserrat', sans-serif;
    background-color: #e83539;
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 0;
    font-size: 1.2rem;
}

.btn-custom:hover {
    background-color: #cc2e31;
    color: white;
}

.btn-custom:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.form-control {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid;
    border-color: white;
    border-radius: 0px;
    color: white;
    margin-bottom: 20px;
}

.form-control::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.loading-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top: 2px solid transparent;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
</style>